import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroupDirective, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Quill from 'quill';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

import { SubjectsService } from '@shared/services/subjects.service';
import { CustomControlAbstract } from '@ui-components/controls/custom-control.abstract';

@UntilDestroy()
@Component({
  selector: 'app-wysiwyg-editor',
  templateUrl: './wysiwyg-editor.component.html',
  styleUrls: ['./wysiwyg-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WysiwygEditorComponent extends CustomControlAbstract<string> implements OnInit {
  @Input() placeholder: string;
  @Input() readonly = false;
  @Input() maxLength = 1000;
  @Input() styles = { minHeight: '30px', overflow: 'auto' };
  @Input() showCurrentLength = true;

  @Output() onFocus = new EventEmitter<void>();
  @Output() onBlur = new EventEmitter<void>();

  editor$ = new BehaviorSubject<Quill | null>(null);
  currentLength$: Observable<number>;
  isValid: boolean;
  focused = false;
  modules = {
    clipboard: {
      matchVisual: false, // prevents auto <p><br /></p> between paragraphs
    },
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      //['blockquote', 'code-block'],

      //[{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      // [{ direction: 'rtl' }], // text direction

      // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],

      // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      // [{ font: [] }],
      // [{ align: [] }],

      ['clean'], // remove formatting button

      // ['link', 'image', 'video'], // link and image, video
    ],
  };

  constructor(
    @Self() @Optional() protected ngControl: NgControl,
    protected cdr: ChangeDetectorRef,
    @Optional() formDirective: FormGroupDirective,
    private subjectsService: SubjectsService
  ) {
    super(ngControl, cdr, formDirective);
  }

  ngOnInit(): void {
    this.initControlBase();
    this.control.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap(value => {
          if (this.isValid) {
            this.onChanged(value);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.currentLength$ = combineLatest([this.editor$, this.control.valueChanges]).pipe(
      filter(([editor]) => !!editor),
      tap(([editor]) => {
        this.isValid = editor.getLength() <= this.maxLength;
      }),
      map(([editor]) => editor.getLength())
    );

    this.subjectsService.templatesFocusOutEditorWithObserveOn$
      .subscribe(() => {
        if (this.focused) this.editor$.getValue().blur();
      })
      .untilDestroyed(this);
  }

  writeValue(value: any): void {
    if (value != this.control.value) {
      this.control.setValue(value);
    }
  }

  editorReady(editor: Quill) {
    this.editor$.next(editor);
  }

  textChanged($event: any) {
    const editor = $event.editor;
    const length = editor.getLength();
    if (length > this.maxLength - 1) {
      const excess = length - this.maxLength - 1;
      editor.deleteText(this.maxLength, excess);
    }
  }
}
