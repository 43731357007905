<div
  class="container"
  mat-dialog-title
  cdkDrag
  cdkDragHandle
  cdkDragRootElement=".cdk-overlay-pane"
  [cdkDragDisabled]="isBlockDraggable">
  <div class="header-title display-flex align-items-center">
    <div class="display-flex flex-column grid-gap-8 flex-grow-1">
      <div class="display-flex align-items-center grid-gap-8">
        <app-template-editor-header
          (mouseout)="isBlockDraggable = false"
          (mouseover)="isBlockDraggable = true"
          [header]="inspectionTemplate?.name ?? data.templateHeader"
          [isEditable]="!(isLoading$ | async) && data.isEditable"
          (headerChanged)="headerChange($event)"></app-template-editor-header>
        <span class="action-buttons" (mouseout)="isBlockDraggable = false" (mouseover)="isBlockDraggable = true">
          <button
            aria-label="delete-button"
            type="button"
            class="icon-button action-buttons__item action-buttons__item-remove"
            mat-icon-button
            [disabled]="isLoading$ | async"
            (click)="removeTemplate()">
            <app-icon [svg]="EIcon.DELETE_FOREVER"></app-icon>
          </button>
          <button
            aria-label="dots-button"
            (menuOpened)="actionItemMenuOpen = true"
            (menuClosed)="actionItemMenuOpen = false"
            [style.opacity]="actionItemMenuOpen ? '1' : undefined"
            [matMenuTriggerFor]="actionItemMenu"
            [disabled]="isLoading$ | async"
            type="button"
            class="icon-button action-buttons__item"
            mat-icon-button>
            <app-icon [svg]="EIcon.VERTICAL_DOTS"></app-icon>
          </button>
        </span>
      </div>
    </div>
    <div *ngIf="!(isLoading$ | async) && !uploadingXls" [formGroup]="scoringForm" class="scoring-parameters-dropdown">
      <app-radio-button-dropdown
        [attrDisable]="data.isGlobal"
        [containerWidthCss]="'wp-112'"
        formControlName="scoringModel"
        [items]="scoringModelItems"
        *ngIf="isScoringEnabled"
        [attrAppendTo]="'body'"
        [allowClear]="false"
        [allowSearch]="false"
        [autoSortList]="false"
        [isSmallFont]="true"
        [isSmallPadding]="true"
        [containerCss]="'display-flex flex-column'"
        [valueCss]="'padding-left-10'"></app-radio-button-dropdown>
    </div>
    <button
      type="button"
      mat-icon-button
      class="ml-auto"
      (click)="cancel()"
      (mouseout)="isBlockDraggable = false"
      (mouseover)="isBlockDraggable = true">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <ng-container *ngIf="!(isLoading$ | async) && !uploadingXls">
    <div class="template-editor-inspection-description">
      <app-template-editor-description
        [isEditable]="data.isEditable"
        (mouseout)="isBlockDraggable = false"
        (mouseover)="isBlockDraggable = true"
        [description]="inspectionTemplate.description"
        (descriptionChanged)="descriptionChange($event)"></app-template-editor-description>
    </div>
  </ng-container>
</div>
<mat-dialog-content
  cdkScrollable
  [ngStyle]="{ '--expand-duration': expandDuration }"
  (scroll)="onMatDialogScroll($event)">
  <ng-container *ngIf="!uploadingXls; else uploadingXlsSpinner">
    <ng-container *ngIf="!(isLoading$ | async); else spinnerTemplate">
      <app-wysiwyg-editor
        *ngIf="isWelcomeInstructionsEnabled"
        [readonly]="!welcomeInstructionsEditMode"
        (onFocus)="enterInstructionsEditMode()"
        (onBlur)="leaveInstructionsEditMode()"
        [(ngModel)]="welcomeInstructionsText"
        [placeholder]="TemplateEditorInstructionsDefaultValue"></app-wysiwyg-editor>
      <div
        class="template-editor-inspection-dnd-content"
        cdkDropListGroup
        cdkDropList
        #areasDropListGroup="cdkDropList"
        (cdkDropListDropped)="dropDnd($event)"
        [cdkDropListAutoScrollStep]="scrollSpeed">
        <ng-container *ngIf="!initFinished || data.isGlobal; else fullContent">
          <app-template-editor-area
            *ngFor="let area of displayedAreas; trackBy: trackBy"
            class="template-editor-inspection-dnd-content__item dnd-with-expandable"
            cdkDragLockAxis="y"
            cdkDrag
            [id]="'template-area-' + Math.abs(area.id)"
            [cdkDragData]="area"
            (cdkDragStarted)="startDnd($event)"
            appDragCursor
            [isEditable]="data.isEditable"
            [initFinished]="initFinished"
            [template]="inspectionTemplate"
            [area]="area"
            [emitAreaSave]="emitAreaSave$ | async"
            [emitItemSave]="emitItemSave$ | async"
            (areaChange)="areaChange($event)"
            (enterPressed)="enterPressed($event)"
            (clone)="clone($event)"
            (remove)="remove($event)"
            (repeatableChange)="repeatableChange($event)"
            (focusedAreaId)="setFocusedAreaId($event)"
            (focusedItemId)="setFocusedItemId($event)"></app-template-editor-area>
        </ng-container>
        <ng-template #fullContent>
          <app-template-editor-area
            *ngFor="let area of inspectionTemplateContent.areas; trackBy: trackBy"
            class="template-editor-inspection-dnd-content__item dnd-with-expandable"
            cdkDragLockAxis="y"
            cdkDrag
            [id]="'template-area-' + Math.abs(area.id)"
            [cdkDragData]="area"
            (cdkDragStarted)="startDnd($event)"
            appDragCursor
            [isEditable]="data.isEditable"
            [initFinished]="initFinished"
            [template]="inspectionTemplate"
            [area]="area"
            [emitAreaSave]="emitAreaSave$ | async"
            [emitItemSave]="emitItemSave$ | async"
            (areaChange)="areaChange($event)"
            (enterPressed)="enterPressed($event)"
            (clone)="clone($event)"
            (remove)="remove($event)"
            (repeatableChange)="repeatableChange($event)"
            (focusedAreaId)="setFocusedAreaId($event)"
            (focusedItemId)="setFocusedItemId($event)"></app-template-editor-area>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #spinnerTemplate>
    <div class="spinner-container">
      <app-skeleton-loader class="w-100 h-100"></app-skeleton-loader>
    </div>
  </ng-template>
  <ng-template #uploadingXlsSpinner>
    <div class="display-flex align-items-center justify-content-center upload-spinner-container">
      <div class="upload-template-spinner">
        <mat-spinner class="spinner-border" diameter="80"></mat-spinner>
      </div>
    </div>
  </ng-template>
</mat-dialog-content>
<div class="actions">
  <div>
    <button
      class="plus-button"
      [ngStyle]="{ opacity: initFinished ? '1' : '0.5' }"
      [matTooltip]="'Add new area'"
      matTooltipShowDelay="500"
      mat-button
      [disabled]="(isLoading$ | async) || !initFinished"
      (click)="onSaveArea(); add()"
      (mouseout)="isBlockDraggable = false"
      (mouseover)="isBlockDraggable = true">
      <span class="display-flex align-items-center justify-content-center add-button">
        <app-icon
          [iconWidth]="20"
          [iconHeight]="20"
          [iconColor]="EColorPalette.cGray7"
          class="add-button__icon"
          [svg]="EIcon.PLUS"></app-icon>
        <span class="add-button__text">area</span>
      </span>
    </button>
    <button
      class="plus-button"
      [ngStyle]="{ opacity: initFinished ? '1' : '0.5' }"
      [matTooltip]="'Add new item'"
      matTooltipShowDelay="500"
      mat-button
      [disabled]="(isLoading$ | async) || !initFinished"
      (click)="onSaveArea(); add(true)">
      <span class="display-flex align-items-center justify-content-center add-button">
        <app-icon
          [iconWidth]="20"
          [iconHeight]="20"
          [iconColor]="EColorPalette.cGray7"
          class="add-button__icon"
          [svg]="EIcon.PLUS"></app-icon>
        <span class="add-button__text">Item</span>
      </span>
    </button>
  </div>
  <div class="action-buttons">
    <button
      aria-label="download-button"
      type="button"
      class="icon-button"
      mat-icon-button
      [matTooltip]="'Download as .xlsx (Inspect Pro only)'"
      matTooltipShowDelay="500"
      [disabled]="downloadInProgress$ | async"
      (click)="downloadFile()">
      <app-icon *ngIf="!(downloadInProgress$ | async)" [svg]="EIcon.DOWNLOAD_ICON"></app-icon>
      <mat-spinner color="accent" diameter="18" *ngIf="downloadInProgress$ | async"></mat-spinner>
    </button>
    <button
      aria-label="upload-button"
      type="button"
      class="icon-button"
      mat-icon-button
      [matTooltip]="'Upload an .xlsx (Inspect Pro only)'"
      matTooltipShowDelay="500"
      [disabled]="uploadInProgress$ | async"
      (click)="uploadFile()">
      <app-icon [svg]="EIcon.UPLOAD_ICON" *ngIf="!(uploadInProgress$ | async)"></app-icon>
      <mat-spinner color="accent" diameter="18" *ngIf="uploadInProgress$ | async"></mat-spinner>
    </button>
    <input
      #fileUpload
      aria-label="upload-input"
      class="display-none"
      tabIndex="-1"
      type="file"
      accept=".xls,.xlsx"
      (change)="fileChange($event)" />
  </div>
</div>

<mat-menu #actionItemMenu="matMenu" class="menu-with-backdrop actions-menu">
  <button type="button" class="actions-menu__item" mat-menu-item (click)="inspectionScoringChange()">
    <div class="display-flex justify-content-space-between info-item">
      <span class="text-color dark">Enable Inspection Scoring</span>
      <app-icon *ngIf="isScoringEnabled" [svg]="EIcon.CHECK_YES"></app-icon>
    </div>
  </button>
  <button type="button" class="actions-menu__item" mat-menu-item (click)="showDefaultAnswersChange()">
    <div class="display-flex justify-content-space-between info-item">
      <span class="text-color dark">Show Default Answers in the Report</span>
      <app-icon *ngIf="inspectionTemplate?.showDefaultAnswersInTheReport" [svg]="EIcon.CHECK_YES"></app-icon>
    </div>
  </button>
  <button type="button" class="actions-menu__item" mat-menu-item (click)="pmSignatureChange()">
    <div class="display-flex justify-content-space-between info-item">
      <span class="text-color dark">Show PM Signature</span>
      <app-icon *ngIf="!inspectionTemplate?.hidePropertyManagerSignature" [svg]="EIcon.CHECK_YES"></app-icon>
    </div>
  </button>
  <button type="button" class="actions-menu__item" mat-menu-item (click)="residentSignatureChange()">
    <div class="display-flex justify-content-space-between info-item">
      <span class="text-color dark">Show Resident Signature</span>
      <app-icon *ngIf="inspectionTemplate?.residentSignatureRequired" [svg]="EIcon.CHECK_YES"></app-icon>
    </div>
  </button>
  <button
    [disabled]="isWelcomeInstructionsDisabled"
    type="button"
    class="actions-menu__item"
    mat-menu-item
    (click)="welcomeInstructionsChange()">
    <div class="display-flex justify-content-space-between info-item">
      <span class="text-color dark">Show Welcome instructions</span>
      <app-icon *ngIf="isWelcomeInstructionsEnabled" [svg]="EIcon.CHECK_YES"></app-icon>
    </div>
  </button>
</mat-menu>
