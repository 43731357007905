<div class="rating-group-item">
  <div class="emoji" (click)="onMakeDefault(ratingGroupItem)" [ngClass]="{ active: ratingGroupItem.item.isDefault }">
    <app-template-editor-animation
      *ngIf="showEmoji"
      class="emoji-animation"
      [animationItem]="ratingGroupItem.item.emoji"
      [attrWidth]="'48px'"
      [attrHeight]="'48px'"></app-template-editor-animation>
    <app-icon
      (click)="onSaveArea()"
      class="edit-rating-icon"
      appStopPropagation
      [svg]="EIcon.EDIT"
      [matMenuTriggerFor]="contextMenu"
      [matMenuTriggerData]="{ item: ratingGroupItem }"></app-icon>
  </div>

  <mat-menu #contextMenu="matMenu" class="menu-with-backdrop">
    <ng-template matMenuContent let-item="item">
      <div class="emojis-context-menu" [formGroup]="form">
        <button
          mat-menu-item
          (click)="onMakeDefault(item)"
          class="bold display-flex justify-content-space-between align-items-center">
          <span>Default Rating</span>
          <app-icon *ngIf="item.item.isDefault" [svg]="EIcon.CHECK_YES"></app-icon>
        </button>
        <div mat-menu-item appStopPropagation class="bold display-flex align-items-center">
          <app-number-input
            class="emoji-template-item"
            containerCss="no-borders"
            formControlName="timeMultiplier"
            [labelInside]="true"
            [classList]="'emoji-template-item'"
            [label]="'Time Multiplier'"></app-number-input>
        </div>
        <div mat-menu-item appStopPropagation class="bold display-flex align-items-center">
          <app-number-input
            class="emoji-template-item"
            containerCss="no-borders"
            formControlName="materialsMultiplier"
            [labelInside]="true"
            [classList]="'emoji-template-item'"
            [label]="'Materials Multiplier'"></app-number-input>
        </div>
        <div
          *ngIf="showResultScore"
          class="bold display-flex justify-content-space-between"
          mat-menu-item
          appStopPropagation>
          <span class="result-score-title">Result Score</span>
          <app-radio-button-dropdown
            class="scoring-capsule"
            formControlName="resultScore"
            [items]="resultScoreItems"
            [attrAppendTo]="'body'"
            [allowClear]="false"
            [allowSearch]="false"
            [autoSortList]="false"
            [isSmallFont]="true"
            [attrPlaceholder]="'Score'"
            [containerCss]="'display-flex flex-column'"
            [valueCss]="'small-font'"
            [ngSelectCss]="'remove-border'"></app-radio-button-dropdown>
        </div>
        <div
          mat-menu-item
          appStopPropagation
          (click)="toggleShowQty()"
          class="bold display-flex justify-content-space-between align-items-center">
          <span>Show Qty</span>
          <app-slide-toggle formControlName="showQtyWidget"></app-slide-toggle>
        </div>

        <div
          mat-menu-item
          appStopPropagation
          (click)="toggleShowHours()"
          class="bold display-flex justify-content-space-between align-items-center">
          <span>Show Hours</span>
          <app-slide-toggle formControlName="showHoursWidget"></app-slide-toggle>
        </div>

        <div
          mat-menu-item
          appStopPropagation
          (click)="toggleTicketRequired()"
          class="bold display-flex justify-content-space-between align-items-center">
          Ticket Required
          <app-slide-toggle formControlName="ticketRequired"></app-slide-toggle>
        </div>
        <div
          mat-menu-item
          appStopPropagation
          (click)="toggleShowSpareParts()"
          class="bold display-flex justify-content-space-between align-items-center">
          Show Spare parts
          <app-slide-toggle formControlName="showSpareParts"></app-slide-toggle>
        </div>
        <div
          mat-menu-item
          appStopPropagation
          (click)="toggleSparePartsSelectionRequired()"
          class="bold display-flex justify-content-space-between align-items-center">
          Spare Parts required
          <app-slide-toggle formControlName="sparePartsSelectionRequired"></app-slide-toggle>
        </div>
        <div class="emojis-context-menu__container">
          <div appStopPropagation class="emojis-context-menu__left">
            <mat-icon [svgIcon]="'angleLeft'" (click)="slideLeft()"></mat-icon>
          </div>
          <swiper
            *ngIf="emojiSlides.length"
            (slideChange)="emojiSlideChanged($event)"
            (swiper)="onSwiper($event)"
            class="emojis-context-menu__swiper"
            [config]="config">
            <ng-container *ngFor="let group of emojiSlides; let index = index">
              <ng-template swiperSlide>
                <div class="emojis-context-menu__emojis">
                  <app-template-editor-animation
                    class="emojis-context-menu__emoji"
                    *ngFor="let emoji of group"
                    [attrWidth]="'36px'"
                    [attrHeight]="'36px'"
                    [autoplay]="index === activeEmojiSlideIndex"
                    [assetRelativeUrl]="emoji.assetRelativeUrl"
                    (click)="changeIcon(emoji)"></app-template-editor-animation>
                </div>
              </ng-template>
            </ng-container>
          </swiper>
          <div appStopPropagation class="emojis-context-menu__right">
            <mat-icon [svgIcon]="'angleRight'" (click)="slideRight()"></mat-icon>
          </div>
        </div>
        <div class="emojis-context-menu__navigation">
          <div
            appStopPropagation
            (click)="setEmojiSlideIndex(index)"
            *ngFor="let group of emojiSlides; let index = index"
            class="emojis-context-menu__bullet"
            [class.active]="activeEmojiSlideIndex === index"></div>
        </div>
      </div>
    </ng-template>
  </mat-menu>

  <div class="rating-icon-group">
    <button
      aria-label="photo-button"
      type="button"
      class="rating-icon"
      [ngClass]="{ active: ratingGroupItem.item.onSelect.includes('photoRequired') }"
      mat-icon-button
      [matTooltip]="'Require a photo if this rating is selected'"
      matTooltipShowDelay="500"
      (click)="togglePhotoOnSelect()">
      <app-icon [svg]="EIcon.PHOTO_CAMERA"></app-icon>
    </button>
    <button
      aria-label="comment-button"
      type="button"
      class="rating-icon"
      [ngClass]="{ active: ratingGroupItem.item.onSelect.includes('commentRequired') }"
      mat-icon-button
      [matTooltip]="'Require a comment if this rating is selected'"
      matTooltipShowDelay="500"
      (click)="toggleCommentOnSelect()">
      <app-icon [svg]="EIcon.COMMENT_CIRCLE"></app-icon>
    </button>
  </div>
</div>
<div class="text-container">
  <div [matTooltip]="ratingName.textContent" matTooltipShowDelay="500">
    <div
      class="text-block"
      #ratingName
      [attr.contenteditable]="editMode"
      (click)="enterEditMode()"
      [attr.placeholder]="TemplateEditorDefaultValue"
      (blur)="leaveEditMode(ratingName.textContent)"
      (keydown)="onKeydown($event, ratingName.textContent)">
      <ng-container>{{ ratingGroupItem.item.text }}</ng-container>
    </div>
  </div>
  <button aria-label="delete-button" type="button" class="rating-icon delete-icon" mat-icon-button (click)="delete()">
    <app-icon [svg]="EIcon.DELETE_FOREVER"></app-icon>
  </button>
</div>
