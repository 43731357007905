import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { InputErrorModule } from '@ui-components/components/input-error/input-error.module';
import { SelectTreeModule } from '@ui-components/components/select-tree/select-tree.module';
import { InputModule } from '@ui-components/controls/input/input.module';
import { PhoneNumberInputComponent } from '@ui-components/controls/phone-number-input/phone-number-input.component';
import { RadioButtonModule } from '@ui-components/controls/radio-button/radio-button.module';
import { RadioButtonDropdownModule } from '@ui-components/controls/radio-button-dropdown/radio-button-dropdown.module';

import { UserPreviewModalComponent } from './user-preview-modal.component';

@NgModule({
  declarations: [UserPreviewModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    InputModule,
    RadioButtonDropdownModule,
    SelectTreeModule,
    RadioButtonModule,
    PhoneNumberInputComponent,
    InputErrorModule,
    MatCheckboxModule,
  ],
})
export class UserPreviewModalModule {}
